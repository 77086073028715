<template>
  <div class="fragment">
    <CountCorrectTableAdmin/>
  </div>
</template>

<script>
  import CountCorrectTableAdmin from "./CountCorrectTableAdmin/CountCorrectTableAdmin";

  export default {
    name: "CountCorrectTable",
    components: {
      CountCorrectTableAdmin,
    }
  }
</script>

<style scoped>

</style>