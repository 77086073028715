<template>
  <div class="table-filter-wrap">
    <div class="site-table-wrap small-table products-table">
      <table class="site-table" v-if="!isMobileFunc()">
        <thead>
        <tr>
          <th>UPC</th>
          <th>SKU</th>
          <th>Product Name</th>
          <th>Name from Etsy or Ebay</th>
          <th>Status</th>
          <th>FBM</th>
          <th>FBA</th>
          <th>Returns</th>
          <th>Damaged</th>
          <th>Manage</th>
        </tr>
        </thead>
        <tbody>

        <tr>
          <td>
            #3456363
          </td>
          <td>
            <span class="ellipsis ellipsis--100">
              SKU [3298r] UPC[erjhi4iku] UPC[erjhi4iku] UPC[erjhi4iku]
            </span>
          </td>
          <td>
            <span class="ellipsis">
              Vibrating Foam Massage Roller Foam Massage Roller
            </span>
          </td>
          <td>
            Product
          </td>
          <td>

            <div class="white-space-line">
              In Transit

              <span class="table-link btn-style">
                Return
              </span>
            </div>

          </td>
          <td>
            <div class="table-row table-row--input">
              <DefaultInput
                class="center white"
                v-model="fbm"
              />
            </div>
          </td>
          <td>
            <div class="table-row table-row--input">
              <DefaultInput
                  class="center white"
                  v-model="fba"
              />
            </div>
          </td>
          <td>
            <div class="table-row table-row--input">
              <DefaultInput
                  class="center white"
                  v-model="returns"
              />
            </div>
          </td>
          <td>
            <div class="table-row table-row--input">
              <DefaultInput
                  class="center white"
                  v-model="damaged"
              />
            </div>
          </td>
          <td class="manage-row">
            <div class="table-right table-manage-list table-manage-list--small">

              <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                <template slot="item">
                  <LinkButton
                          :value="'Save'"
                          :type="'edit'"
                  />
                </template>
              </ManagerButton>
            </div>
          </td>
        </tr>

        </tbody>
      </table>

      <div class="table-card" v-else>
        <div class="table-card__list">
          <div class="table-card__item">
            <div class="table-card__item-content"
                 :class="{show: show1}"
            >
              <div class="table-card__item-head">
                <div class="table-card__item-head-row">
                  <div class="table-card__item-number">
                    #3456363
                  </div>
                </div>
              </div>
              <transition name="slide">
                <div class="table-card__item-body"
                     v-if="show1"
                >
                  <div class="table-card__item-row custom-row">
                    <div class="table-card__item-col custom-col">
                      <div class="table-card__item-label">
                        UPC
                      </div>
                      <div class="table-card__item-info">
                        #3456363
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col">
                      <div class="table-card__item-label">
                        SKU
                      </div>
                      <div class="table-card__item-info">
                        SKU [3298r] UPC[erjhi4iku] UPC[erjhi4iku] UPC[erjhi4iku]
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col">
                      <div class="table-card__item-label">
                        Product Name
                      </div>
                      <div class="table-card__item-info">
                        Vibrating Foam Massage Roller Foam Massage Roller
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col">
                      <div class="table-card__item-label">
                        Name from Etsy or Ebay
                      </div>
                      <div class="table-card__item-info">
                        Product
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col">
                      <div class="table-card__item-label">
                        Status
                      </div>
                      <div class="table-card__item-info">
                        <div class="white-space-line">
                          In Transit
                          <span class="table-link btn-style">
                            Return
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        FBM
                      </div>
                      <div class="table-card__item-info">
                        <DefaultInput
                            class="center white"
                            v-model="fba"
                        />
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        FBA
                      </div>
                      <div class="table-card__item-info">
                        <div class="table-card__item-info flex-wrap">
                          <DefaultInput
                              class="center white"
                              v-model="returns"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Returns
                      </div>
                      <div class="table-card__item-info">
                        <DefaultInput
                            class="center white"
                            v-model="damaged"
                        />
                      </div>
                    </div>
                    <div class="table-card__item-col custom-col custom-col--50">
                      <div class="table-card__item-label">
                        Damaged
                      </div>
                      <div class="table-card__item-info">
                        <DefaultInput
                            class="center white"
                            v-model="damaged"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="table-card__item-btn">
                    <div class="table-card__item-btn-i">
                      <LinkButton
                          :value="'Save'"
                          :type="'edit'"
                      />
                    </div>
                  </div>
                </div>
              </transition>
              <div class="table-card__item-footer">
                <div class="table-card__item-show"
                     @click="show1 = !show1"
                >
                  Show More
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore :count="'25'"/>
          <ExportBtn class="table-bottom-btn__right"/>
        </div>
      </div>
    </div>


    <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->



  </div>
</template>

<script>
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";


  export default {
    name: "CountCorrectTable",

    components: {
      // MainButton,
      // DefaultInput,
      ShowMore,
      ExportBtn,
      LinkButton,
      ManagerButton,
      // ProductsToWarehouse,
      DefaultInput,
    },

    mixins: [mixinDetictingMobile],

    // mounted() {
    //   this.isMobile = this.isMobileFunc
    //
    //   window.addEventListener('resize', () => {
    //     this.isMobile = this.isMobileFunc
    //   })
    // },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        fbm: 1,
        fba: 1,
        returns: 1,
        damaged: 1,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,

        isModalCommentsPopup: '',
        isModalSendEmailPopup: '',
        isModalLastFiveIPPopup: '',
        isModalStatisticPopup: '',
        isModalInfoIDPopup: '',

      }
    },

    methods: {
      closePopup(){
        // this.isModalProductsToWarehouse = false
      },

      changeCommentsPopup(val){
        this.isModalCommentsPopup = val
      },
      changeSendEmailPopup(val){
        this.isModalSendEmailPopup = val
      },
      changeLastFiveIPPopup(val){
        this.isModalLastFiveIPPopup = val
      },
      changeStatisticPopup(val){
        this.isModalStatisticPopup = val
      },
      changeInfoIDPopup(val){
        this.isModalInfoIDPopup = val
      },

      cantDeleteProduct(){
        let text = {
          title: 'Ooops',
          txt: 'Sorry, you cannot uninstall this product',
          no: 'Ok'
        }

        let confirm = () => {
          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      removeProduct(){
        let text = {
          title: 'Delete Product?',
          txt: 'Are you sure you want to Delete Product?',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          // this.$store.dispatch('deleteShopPermission', id).then((response) => {
          //   if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          //     let shops = this.$store.getters.getShops
          //     shops.map((item, index) => {
          //       if(item.id === id) {
          //         shops[index].shop_oauth_param.key_and_token_exist = 0
          //       }
          //     })
          //   } else {
          //     setTimeout(() => {
          //       this.openNotify('success', 'Произошла ошибка')
          //     }, 200)
          //   }
          // })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      }
    }

  }
</script>

<style lang="scss" scoped>

  /*.site-table thead th,*/
  /*.site-table tbody td{*/
    /*padding-right: 5px;*/
    /*padding-left: 5px;*/
  /*}*/

  .manage-row,
  .products-table .table-manage-list .manager-btn{
    width: 90px;
  }

  /*.site-table thead th:first-child,*/
  /*.site-table tbody td:first-child{*/
    /*padding-right: 0;*/
  /*}*/

  /*.site-table thead th:nth-child(2),*/
  /*.site-table tbody td:nth-child(2){*/
    /*padding-left: 0;*/
  /*}*/

  .products-table .site-table thead th:last-child,
  .products-table .site-table tbody td:last-child {
    padding-right: 0;
  }

  .products-table .ellipsis{
    max-width: 170px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;

    &--100{
      max-width: 100px;
    }
  }

  @media(max-width: 1600px){
    .hide-1600{
      display: none;
    }
  }

</style>
