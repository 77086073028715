
<template>
  <div class="fragment">

    <div class="search-engine-block search-engine-block--single">
      <div class="search-engine-block__inner">
        <div class="search-engine-block__title"></div>
        <div class="search-engine-block__list">
          <div class="search-engine-block__row custom-row">
            <div class="search-engine-block__col custom-col custom-col--25 custom-col--xl-50  custom-col--sm-100 ml-auto">
              <SearchInput
                  :type="'text'"
                  :label="'Search for the transaction'"
                  v-model="searchField5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  // import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import PrintButton from "../../../../../../UI/buttons/PrintButton/PrintButton";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import SearchInput from "../../../../../../UI/inputs/SearchInput/SearchInput";


  export default {
    name: "CountCorrectHead",
    components: {
      // MainButton,
      // ToggleFilterButton,
      // PlusIconSVG,
      // PrintButton,
      // DefaultCheckbox,
      SearchInput,
    },

    props: [
      'showFilter', 'adminTabs', 'countFilterParams'
    ],

    watch: {

    },

    created() {

    },

    data() {
      return {
        isShowSearchEngineBlock: false,
        searchField1: '',
        searchField2: '',
        searchField3: '',
        searchField4: '',
        searchField5: '',
      }
    },



    methods: {


      changeTab(val) {
        this.$emit('changeTab', val)
      },

      selectAll(val) {
        this.$emit('selectAll', val)
      },

      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },
    }
  }
</script>

