<template>
  <div>
    <CountCorrectHead
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeFilter="changeFilter"
        :showFilter="showFilter"
    />

    <div class="table-filter-wrap">

      <CountCorrectTable
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
      />
    </div>
  </div>
</template>

<script>


  import CountCorrectHead from "./CountCorrectHead/CountCorrectHead";
  import CountCorrectTable from "./CountCorrectTable/CountCorrectTable";

  export default {
    name: "CountCorrectTableAdmin",

    components: {
      CountCorrectHead,
      CountCorrectTable,
    },

    props: [
      'typeShops',
      'filterGetParams',
      'countFilterParams',
    ],

    data(){
      return{
        showFilter: false,
        filterId: '',
        filterName: '',
        forPage: 10,
        page: 1,

        isModalCommentsPopup: '',
        isModalSendEmailPopup: '',
        isModalLastFiveIPPopup: '',
        isModalStatisticPopup: '',
      }
    },

    watch: {
      typeShops(newVal) {
        this.typeShops = newVal
      },
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },


      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

      selectAll(val) {
        this.selectedNow = val
      },

      changeCommentsPopup(val){
        this.isModalCommentsPopup = val
      },
      changeSendEmailPopup(val){
        this.isModalSendEmailPopup = val
      },
      changeLastFiveIPPopup(val){
        this.isModalLastFiveIPPopup = val
      },
      changeStatisticPopup(val){
        this.isModalStatisticPopup = val
      },
    }
  }
</script>

<style scoped>

</style>

